import React from "react"
import Layout from "../../components/layout";
import {Container, Row, Col} from "react-bootstrap";
import Hero from "../../components/hero";
import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "red-255110_1920.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const bgImage = data.desktop.childImageSharp.fluid;
  return (
    <Layout>
      <Hero
        BgColor={"#ff333a"}
        Title={"Additional Services"}
        Img={bgImage}
      />
      <Container>
        <Row>
          <Col>
            <h2 id={"new"}>New Car Service</h2>
            <p>This service involves a thorough hand washing, drying and vacuuming of your vehicle. A Clay Bar will be used to remove contaminants found on your vehicle. Then a high-quality paint sealant will be applied. The paint sealant will provide resistance from UV rays, salt air, snow, sleet, insects, road film, harsh detergents and environmental contaminants that can fade or discolor automotive finishes prematurely.</p>
            <p>Fabric protectant is applied to all fabric, floors, and floor mats. It helps protect fabrics from absorbing spills in your vehicle. Leather surfaces receive an application of leather conditioner.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 id={"interior"}>Interior Deodorizing</h2>
            <p>If a bad smell is lingering in your vehicle, clear the air with an ozone treatment. Ozone generators use nature’s powerful deodorizer to help remove odors and clean the air in your vehicle.</p>
            <p>Ozone (O3) contains three atoms of oxygen. The third oxygen atom makes ozone extremely reactive, which readily attaches itself to other molecules. When pollutants meet ozone, oxidation reactions occur and both parties are destroyed. Ozone essentially reverts back to oxygen. It permanently neutralizes and destroys recurring unpleasant odors at the molecular level, while leaving a clean, fresh smell.</p>
            <p>Ozone generators eliminate the odors left by: fire, smoke, vomit, urine, feces, skunks, fish, pets, mildew, decay, garbage, etc.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 id={"stainguard"}>Stainguard</h2>
            <p>Stain-resistant fabric protectant is applied to cloth and carpet. This service can be combined with other services.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
